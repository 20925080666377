import { ApolloProvider } from "@apollo/client";
import FeatureFlagContextProvider from "@contexts/FeatureFlagContext";
import usePollLatestVercelGitCommitSha from "@hooks/usePollLatestVercelGitCommitSha";
import useRefreshStaleInactiveTabs from "@hooks/useRefreshStaleInactiveTabs";
import useSetDashboardLoadedAtOnWindow from "@hooks/useSetDashboardLoadedAtOnWindow";
import { NextPage } from "next";
import NextAdapterPages from "next-query-params/pages";
import { QueryParamProvider } from "use-query-params";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { IntercomProvider } from "react-use-intercom";
import { GoogleTagManager } from "@next/third-parties/google";

import "./global.scss";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import client from "../clients/apollo";
import { useRouter } from "next/router";
import Head from "next/head";
import TranslationCacheContextProvider from "@src/contexts/TranslationCacheContext";
import { BodyCursorTypeContextProvider } from "@src/contexts/BodyCursorTypeContext";

const AuthedApp = dynamic(() => import("@components/layout/AuthedApp"), {
  ssr: false,
});
const UnauthedApp = dynamic(() => import("@components/layout/UnauthedApp"), {
  ssr: false,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const INTERCOM_APP_ID = "nqlxuewd";
export const UNAUTHENTICATED_PATHS = ["/signup"];

export const currentRouteIsUnauthenticated = (): boolean => {
  return (
    window &&
    !!UNAUTHENTICATED_PATHS.find((path) =>
      window.location.pathname.startsWith(path),
    )
  );
};

export const useCurrentRouteIsUnauthenticated = (): boolean => {
  const router = useRouter();
  return !!UNAUTHENTICATED_PATHS.find((path) =>
    router.pathname.startsWith(path),
  );
};

export default function _App({
  Component,
  pageProps,
}: AppPropsWithLayout): JSX.Element {
  usePollLatestVercelGitCommitSha();
  useSetDashboardLoadedAtOnWindow();
  useRefreshStaleInactiveTabs();
  useEffect(() => smoothscroll.polyfill(), []);
  const getLayout = useMemo(() => {
    return Component.getLayout ?? ((page: React.ReactNode) => page);
  }, [Component.getLayout]);
  const currentRouteIsUnauthenticated = useCurrentRouteIsUnauthenticated();
  const content = useMemo(() => {
    if (currentRouteIsUnauthenticated) {
      return (
        <UnauthedApp>
          <Component {...pageProps} />
        </UnauthedApp>
      );
    } else {
      return (
        <FeatureFlagContextProvider>
          <AuthedApp>{getLayout(<Component {...pageProps} />)}</AuthedApp>
        </FeatureFlagContextProvider>
      );
    }
  }, [Component, currentRouteIsUnauthenticated, getLayout, pageProps]);
  return (
    <>
      <GoogleTagManager gtmId="GTM-WCL2ZV4J" />
      <QueryParamProvider adapter={NextAdapterPages}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1"
          />
        </Head>
        <ApolloProvider client={client}>
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            autoBoot={process.env.NODE_ENV === "production"}
          >
            <TranslationCacheContextProvider>
              <BodyCursorTypeContextProvider>
                {content}
              </BodyCursorTypeContextProvider>
            </TranslationCacheContextProvider>
          </IntercomProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </>
  );
}
