import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  FieldFunctionOptions,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { CourseFeedbacksQuery } from "@components/contentDetail/courseDetail/feedback/FeedbackTable.generated";
import { GetNotificationsQuery } from "@src/components/layout/top-nav/NotificationBell.generated";
import { HrisEmployeesQuery } from "@components/settings/hris/HrisEmployeeListContainer.generated";
import { HrisLocationsQuery } from "@components/settings/hris/HrisLocationListContainer.generated";
import { HrisTeamsQuery } from "@components/settings/hris/HrisRoleListContainer.generated";
import { currentRouteIsUnauthenticated } from "@pages/_app";
import { cleanVariablesMiddleware } from "@src/clients/middleware/cleanVariablesMiddleware";
import {
  MessageBlastsQuery,
  ReceivedMessagesQuery,
} from "@src/components/messaging/MessagingContainer.generated";
import {
  fetchLatestVercelGitCommitSha,
  getDashboardLoadedAtFromWindow,
  getLatestVercelGitCommitShaFromWindow,
  getVercelGitCommitSha,
} from "@utils/assetsInfo";
import { createUploadLink } from "apollo-upload-client";
import introspectionQueryResultData from "../fragmentTypes.json";
import { mergePaginatedData } from "./utils";
import { GetPeopleQuery } from "@src/components/users/operations.generated";

const link = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  credentials: "include",
});

const headerLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "X-DASHBOARD-CURRENT-ASSETS-COMMIT-SHA":
        getVercelGitCommitSha() || "null",
      "X-DASHBOARD-LATEST-DEPLOYED-COMMIT-SHA":
        getLatestVercelGitCommitShaFromWindow() || "null",
      "X-DASHBOARD-LOADED-AT": getDashboardLoadedAtFromWindow() || "null",
      "X-OPUS-ROLE": "admin",
      "X-DASHBOARD-URL": window.location.href,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    if (currentRouteIsUnauthenticated()) {
      return;
    }
    window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL as string;
  } else if (graphQLErrors && graphQLErrors.length > 0) {
    const oldSha = getVercelGitCommitSha();
    fetchLatestVercelGitCommitSha().then((latestSha) => {
      if (oldSha && latestSha && oldSha !== latestSha) {
        window.location.reload();
      }
    });
  }
});

const apollo = new ApolloClient({
  link: cleanVariablesMiddleware.concat(
    headerLink.concat(errorLink.concat(link as unknown as ApolloLink)),
  ),
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    dataIdFromObject: (responseObject) => {
      if (responseObject.id) {
        return `${responseObject.__typename}:${responseObject.id}`;
      } else if (responseObject.uuid) {
        return `${responseObject.__typename}:${responseObject.uuid}`;
      } else {
        return defaultDataIdFromObject(responseObject);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          TrainingResources: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
          Me: {
            merge(
              existing: Record<string, unknown>,
              incoming: Record<string, unknown>,
            ) {
              return { ...existing, ...incoming };
            },
          },
          AdminActiveClients: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
          AdminGroups: {
            merge(_, incoming: unknown) {
              return incoming;
            },
          },
          Locations: {
            keyArgs: ["input"],
            merge(existing, incoming, options) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          Roles: {
            keyArgs: ["input"],
            merge(existing, incoming, options) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          LocationGroups: {
            keyArgs: ["input"],
            merge(existing, incoming, options) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          RoleGroups: {
            keyArgs: ["input"],
            merge(existing, incoming, options) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          People: {
            keyArgs: ["input"],
            merge(
              existing: GetPeopleQuery["People"] | undefined,
              incoming: GetPeopleQuery["People"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          HrisEmployees: {
            keyArgs: ["input"],
            merge(
              existing: HrisEmployeesQuery["HrisEmployees"] | undefined,
              incoming: HrisEmployeesQuery["HrisEmployees"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          HrisLocations: {
            keyArgs: ["input"],
            merge(
              existing: HrisLocationsQuery["HrisLocations"] | undefined,
              incoming: HrisLocationsQuery["HrisLocations"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          AutomationsV2: {
            keyArgs: ["input"],
            merge(
              existing: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          HrisTeams: {
            keyArgs: ["input"],
            merge(
              existing: HrisTeamsQuery["HrisTeams"] | undefined,
              incoming: HrisTeamsQuery["HrisTeams"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          Assignments: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          SkillInstances: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          PromptInstances: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          VerificationStepInstances: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          SharpAssignments: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          AdminLibrary: {
            keyArgs: ["input"],
            merge(
              existing: any | undefined, // eslint-disable-line  @typescript-eslint/no-explicit-any
              incoming: any, // eslint-disable-line  @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          InProductNotifications: {
            keyArgs: ["input"],
            merge(
              existing: GetNotificationsQuery["Notifications"] | undefined,
              incoming: GetNotificationsQuery["Notifications"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          CourseFeedbacks: {
            keyArgs: ["input"],
            merge(
              existing: CourseFeedbacksQuery["CourseFeedbacks"] | undefined,
              incoming: CourseFeedbacksQuery["CourseFeedbacks"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          MessageBlasts: {
            keyArgs: ["input"],
            merge(
              existing: MessageBlastsQuery["MessageBlasts"] | undefined,
              incoming: MessageBlastsQuery["MessageBlasts"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          EmployeeBlastSentRecordsPaginated: {
            keyArgs: false,
            merge(
              existing:
                | ReceivedMessagesQuery["EmployeeBlastSentRecordsPaginated"]
                | undefined,
              incoming: ReceivedMessagesQuery["EmployeeBlastSentRecordsPaginated"],
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          ManagerLeaderboardPaginated: {
            keyArgs: ["input"],
            merge(
              existing: { objects: any[] } | undefined, // eslint-disable-line   @typescript-eslint/no-explicit-any
              incoming: { objects: any[]; totalCount: number }, // eslint-disable-line   @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          LocationLeaderboard: {
            keyArgs: ["input"],
            merge(
              existing: { objects: any[] } | undefined, // eslint-disable-line   @typescript-eslint/no-explicit-any
              incoming: { objects: any[]; totalCount: number }, // eslint-disable-line   @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          TraineeLeaderboard: {
            keyArgs: ["input"],
            merge(
              existing: { objects: any[] } | undefined, // eslint-disable-line   @typescript-eslint/no-explicit-any
              incoming: { objects: any[]; totalCount: number }, // eslint-disable-line   @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          ComplianceRecords: {
            keyArgs: ["input"],
            merge(
              existing: { objects: any[] } | undefined, // eslint-disable-line   @typescript-eslint/no-explicit-any
              incoming: { objects: any[]; totalCount: number }, // eslint-disable-line   @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
          CompletionScheduleWindows: {
            keyArgs: ["input"],
            merge(
              existing: { objects: any[] } | undefined, // eslint-disable-line   @typescript-eslint/no-explicit-any
              incoming: { objects: any[]; totalCount: number }, // eslint-disable-line   @typescript-eslint/no-explicit-any
              options: FieldFunctionOptions,
            ) {
              return mergePaginatedData(existing, incoming, options);
            },
          },
        },
      },
      TrainingResource: {
        fields: {
          children: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
        },
      },
      ContentTagMembership: {
        keyFields: ["tagId", "libraryItemId"],
      },
      ActiveClient: {
        keyFields: ["clientId"],
      },
      Group: {
        fields: {
          employees: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
        },
      },
      Employee: {
        fields: {
          roles: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
          locations: {
            merge(_, incoming: unknown[]) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default apollo;
